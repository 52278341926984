import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const scheduleData = [
  [
    { from: { hour: '07', minute: '05' }, to: { hour: '07', minute: '25' }, color: 'orange', description: 'Dinner' },
    { from: { hour: '09', minute: '00' }, to: { hour: '12', minute: '00' }, description: 'Singing lesson' },
  ],
  [
    { from: { hour: '07', minute: '05' }, to: { hour: '07', minute: '25' }, color: 'orange', description: 'Dinner' },
    { from: { hour: '09', minute: '11' }, to: { hour: '12', minute: '30' }, description: 'Singing lesson' },
    { from: { hour: '13', minute: '00' }, to: { hour: '13', minute: '20' }, description: 'Singing lesson' },
  ],
  [
    { from: { hour: '07', minute: '05' }, to: { hour: '07', minute: '25' }, color: 'orange', description: 'Dinner' },
    {
      from: { hour: '11', minute: '15' },
      to: { hour: '15', minute: '30' },
      color: 'green',
      description: 'Сhecking assignments',
    },
    { from: { hour: '16', minute: '00' }, to: { hour: '17', minute: '00' }, description: 'Singing lesson' },
  ],
  [
    { from: { hour: '07', minute: '05' }, to: { hour: '07', minute: '25' }, color: 'orange', description: 'Dinner' },
    { from: { hour: '09', minute: '19' }, to: { hour: '09', minute: '55' }, description: 'Singing lesson' },
    {
      from: { hour: '12', minute: '12' },
      to: { hour: '15', minute: '20' },
      color: 'pink',
      description: 'Free time & sports',
    },
  ],
  [
    { from: { hour: '07', minute: '05' }, to: { hour: '07', minute: '25' }, color: 'orange', description: 'Dinner' },
    { from: { hour: '11', minute: '00' }, to: { hour: '13', minute: '20' }, color: 'green', description: 'Journaling' },
    { from: { hour: '15', minute: '00' }, to: { hour: '16', minute: '20' }, description: 'Singing lesson' },
  ],
];

const initialState = {
  isScheduleRequestLoading: false,
  scheduleData: [],
};

export const actionGetScheduleRequest = createAsyncThunk(
  'table/fetchSchedule',
  async ({ TeacherID }) =>
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(scheduleData);
        //console.log(TeacherID);
      }, 1000);
    }),
  // await request('/api/Schedule/', {
  //   method: 'GET',
  //   params: { teacherID },
  // }),
);
export const scheduleSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetScheduleRequest.pending, state => {
      state.isScheduleRequestLoading = true;
    });
    builder.addCase(actionGetScheduleRequest.fulfilled, (state, action) => {
      state.isScheduleRequestLoading = false;
      state.scheduleData = action.payload;
    });
    builder.addCase(actionGetScheduleRequest.rejected, (state, action) => {
      if (action.payload?.errorMessage) {
        state.productsRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.productsRequestError = action.error.message;
      }
      state.isScheduleRequestLoading = false;
    });
  },
  initialState,
  name: 'schedule',
  reducers: {
    resetScheduleState: state => {
      state.isScheduleRequestLoading = false;
      state.scheduleData = initialState.scheduleData;
    },
  },
});

export default scheduleSlice.reducer;
export const { resetScheduleState } = scheduleSlice.actions;
