import { createSlice } from '@reduxjs/toolkit';

import { attendancePageValues } from '../../consts/pages/attendance';
import { studentsPageValues } from '../../consts/pages/students';

const initialState = {
  IDsAcademicYear: 0,

  isTermsOfEntryDataLoading: false,
  termsOfEntryArray: [],
  termsOfEntryHashList: {},
  termsOfEntryActiveStatus: false,
  performancesArray: [],
  performancesHashList: {},
  effortsArray: [],
  effortsHashList: {},
  selectedTermOfEntryID: 0,

  trackingTargetsDataArray: [],
  trackingTargetsDataHashObject: {},
  trackingTargetsErrors: [],
  isTrackingTargetsGetDataLoading: false,

  trackingAssessedDataArray: [],
  trackingAssessedDataHashObject: {},
  trackingAssessedErrors: [],
  isTrackingAssessedGetDataLoading: false,

  fullTerms: [],

  availableMenuItemsHashList: {
    [`${studentsPageValues.pageUrl}/:id`]: 'A,T,D',
    '/digital-notebook': 'A,T',
    '/tracking/assignment': 'T',
    [attendancePageValues.pageUrl]: 'T,S,P,D',
    [studentsPageValues.pageUrl]: 'A,T,D',
    '/teacher/student-tracker-table': 'T',
    '/student-report-individual': 'T',
    '/teacher/form-report': 'T',
    '/overall': 'T',
    '/pendingattendtance': 'T',
    '/reports': 'T',
    '/teacher/student-report-individual': 'T',
    '/teacher/student-objective-tracker-table': 'A, T',
  },

  activeTableRowHoveredID: 0,
};

export const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    selectTermOfEntry: (state, action) => {
      state.selectedTermOfEntryID = Number(action.payload);
    },
    getRequestTermsOfEntryStart: state => {
      state.isTermsOfEntryDataLoading = true;
    },
    getRequestTermsOfEntryError: state => {
      state.isTermsOfEntryDataLoading = false;
    },
    getRequestTermsOfEntrySuccess: (state, action) => {
      const termsOfEntryArray = action.payload || [];
      const termsOfEntryHashList = {};
      const termsOfEntryActiveStatus = action.payload;
      const newTermsOfEntryArray = termsOfEntryArray.map(termOfEntry => ({
        ...termOfEntry,
        isDisabled: termOfEntry.ID > state.currentTermOfEntryID,
      }));
      newTermsOfEntryArray.forEach(termOfEntry => {
        termsOfEntryHashList[termOfEntry.ID] = termOfEntry;
      });
      state.termsOfEntryArray = newTermsOfEntryArray;
      state.termsOfEntryHashList = termsOfEntryHashList;
      state.termsOfEntryActiveStatus = termsOfEntryActiveStatus;
      state.isTermsOfEntryDataLoading = false;
    },
    getRequestPerformancesSuccess: (state, action) => {
      const performancesArray = action.payload || [];
      const performancesHashList = {};
      performancesArray.forEach(termOfEntry => {
        performancesHashList[termOfEntry.ID] = termOfEntry;
      });
      state.performancesArray = performancesArray;
      state.performancesHashList = performancesHashList;
    },
    getRequestEffortsSuccess: (state, action) => {
      const effortsArray = action.payload || [];
      const effortsHashList = {};
      effortsArray.forEach(termOfEntry => {
        effortsHashList[termOfEntry.ID] = termOfEntry;
      });
      state.effortsArray = effortsArray;
      state.effortsHashList = effortsHashList;
    },
    getTrackingTargetsDataRequestStart: state => {
      state.isTrackingTargetsGetDataLoading = true;
      state.trackingTargetsErrors = [];
    },
    getTrackingTargetsDataRequestSuccess: (state, action) => {
      const trackingTargetsDataHashObject = {};
      action.payload.forEach(target => {
        trackingTargetsDataHashObject[target.ID] = target;
      });
      state.isTrackingTargetsGetDataLoading = false;
      state.trackingTargetsErrors = [];
      state.trackingTargetsDataHashObject = trackingTargetsDataHashObject;
      state.trackingTargetsDataArray = action.payload;
    },
    getTrackingTargetsDataRequestError: (state, action) => {
      state.isTrackingTargetsGetDataLoading = false;
      state.trackingTargetsErrors = action.payload;
    },

    getTrackingAssessedDataRequestStart: state => {
      state.isTrackingAssessedGetDataLoading = true;
      state.trackingAssessedErrors = [];
    },
    getTrackingAssessedDataRequestSuccess: (state, action) => {
      const trackingAssessedDataHashObject = {};
      action.payload.forEach(target => {
        trackingAssessedDataHashObject[target.ID] = target;
      });
      state.isTrackingAssessedGetDataLoading = false;
      state.trackingAssessedErrors = [];
      state.trackingAssessedDataHashObject = trackingAssessedDataHashObject;
      state.trackingAssessedDataArray = action.payload;
    },
    getTrackingAssessedDataRequestError: (state, action) => {
      state.isTrackingAssessedGetDataLoading = false;
      state.trackingAssessedErrors = action.payload;
    },
    getCurrentAcademicYearSuccess: (state, action) => {
      const { IDsAcademicYear, TermID, StartDate } = action.payload;
      state.IDsAcademicYear = IDsAcademicYear || '';
      state.currentTermOfEntryID = TermID;
      state.currentTermStartDate = StartDate;
    },

    getFullTermsSuccess: (state, action) => {
      state.fullTerms = action.payload;
    },

    getAvailableMenuItemsHashListSuccess: (state, action) => {
      state.availableMenuItemsHashList = action.payload;
    },

    setActiveTableRowHoveredID: (state, action) => {
      state.activeTableRowHoveredID = action.payload;
    },
  },
});

export default commonSlice.reducer;
export const {
  getRequestPerformancesSuccess,
  getRequestEffortsSuccess,
  getRequestTermsOfEntrySuccess,
  selectTermOfEntry,
  getTrackingTargetsDataRequestStart,
  getTrackingAssessedDataRequestStart,
  getTrackingAssessedDataRequestError,
  getTrackingAssessedDataRequestSuccess,
  getTrackingTargetsDataRequestError,
  getTrackingTargetsDataRequestSuccess,
  getCurrentAcademicYearSuccess,
  getRequestTermsOfEntryError,
  getRequestTermsOfEntryStart,
  getFullTermsSuccess,
  getAvailableMenuItemsHashListSuccess,
  setActiveTableRowHoveredID,
} = commonSlice.actions;
